<template>
  <Dialog header="Claim your CME Credit" :draggable="false" v-model:visible="displayDialog" :dismissableMask="true" :modal="true" :blockScroll="true" :breakpoints="{ '900px': '75vw', '640px': '90vw' }" style="max-width: 600px">
    <form v-show="lectureCreditFormSubmitted != true" @submit.prevent="submit" class="border-2 border-white border-solid rounded-lg">
      <Message severity="info" :closable="false" class="text-left !mt-0">Please complete the feedback form below.</Message>
      <div class="flex flex-col" :key="questionIndex">
        <template v-if="selectedQuestion.type === 'multiselect'">
          <div class="animated fade-in-left animated-delay-3">
            <span class="mb-2 flex flex-row"> {{ selectedQuestion.question }} </span>
            <span v-if="errors.question" class="text-[#dc3545] mb-2">{{ errors.question }}</span>
            <div class="grid grid-cols-1 md:grid-cols-2 items-start gap-4 mt-2">
              <div v-for="(option, idx) in selectedQuestion.options" :key="idx" class="flex flex-row">
                <div class="flex">
                  <Checkbox v-model="option.value" :inputId="`multiselect-option-${idx}`" :binary="true" />
                </div>
                <label :for="`multiselect-option-${idx}`" class="ml-2 cursor-pointer">{{ option.question }}</label>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="selectedQuestion.type === 'single'">
          <div class="animated fade-in-left animated-delay-3">
            <div class="mb-2 flex flex-row">{{ selectedQuestion.question }}</div>
            <div v-if="errors.question" class="text-[#dc3545] mb-2">{{ errors.question }}</div>

            <div class="grid grid-cols-2 items-start gap-4">
              <div v-for="(option, idx) in selectedQuestion.options" :key="idx" class="flex flex-row">
                <div class="flex">
                  <Checkbox v-model="option.value" :inputId="option.question.replace(/\s/g, '')" :binary="true" @change="yesNoOnClick(option)" />
                </div>
                <label :for="option.question.replace(/\s/g, '')" class="ml-2 cursor-pointer">{{ option.question }}</label>
              </div>
            </div>
            <template v-if="selectedSubQuestion">
              <div class="mt-4 mb-2 flex flex-row" v-if="selectedSubQuestion?.options?.length !== 0">Please select all that apply</div>
              <div class="mt-4 mb-2 flex flex-row" v-else>Please describe how this information may be harmful</div>
              <div v-if="errors.subQuestion" class="text-[#dc3545] mb-2">{{ errors.subQuestion }}</div>
              <div class="grid grid-cols-1 md:grid-cols-2 items-start gap-4">
                <div v-for="(option, idx) in selectedQuestion.subQuestions.find((subQuestion) => subQuestion.question === selectedQuestion.options.find((option) => option.value)?.question).options" :key="idx" class="flex flex-row">
                  <div class="flex">
                    <Checkbox v-model="option.value" :inputId="idx" :binary="true" />
                  </div>
                  <label :for="idx" class="ml-2 cursor-pointer"> {{ option.question }}</label>
                </div>
              </div>
              <div v-if="errors.otherTextValue" class="text-[#dc3545] mt-2 -pb-2">{{ errors.otherTextValue }}</div>
              <div v-if="selectedSubQuestion?.options?.length === 0 || selectedSubQuestion?.options.find((option) => option.question === 'Other')?.value">
                <div :class="selectedSubQuestion?.options?.length === 0 ? '' : 'mt-4'">
                  <Textarea v-model="selectedQuestion.subQuestions.find((subQuestion) => subQuestion.question === selectedQuestion.options.find((option) => option.value)?.question).otherTextValue" class="auth-input" :autoResize="true" rows="5" cols="30" required maxLength="65535" placeholder="Please describe this problem" />
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-else-if="selectedQuestion.type === 'final'">
          <div class="animated fade-in-left animated-delay-3">
            <div>
              <div v-for="(question, idx) in selectedQuestion.questions" :key="idx" class="flex flex-col">
                <div class="mb-2 flex flex-row">{{ question.question }}</div>
                <div v-if="errors[`question-${idx}`]" class="text-[#dc3545] mb-2">{{ errors[`question-${idx}`] }}</div>
                <div class="grid grid-cols-2 items-start gap-4">
                  <div v-for="option in question.options" :key="option" class="flex flex-row mb-4">
                    <div class="flex">
                      <Checkbox v-model="option.value" :inputId="`${option.question}-${idx}`" :binary="true" @change="yesNoFinalOnClick(question, option)" />
                    </div>
                    <label :for="`${option.question}-${idx}`" class="ml-2 cursor-pointer">{{ option.question }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <Textarea v-model="selectedQuestion.otherTextValue" class="auth-input" :autoResize="true" rows="5" cols="30" maxLength="65535" placeholder="Please describe this problem" />
            </div>
          </div>
        </template>
      </div>
    </form>
    <div v-show="lectureCreditFormSubmitted == true" class="flex flex-col items-center justify-center px-16 py-24 border-2 border-gray-200 border-solid rounded-lg">
      <i class="mt-2 mb-6 fas fa-award text-blue" style="font-size: 2.5rem"></i>
      <h2 class="mb-6 text-2xl text-center sm:text-left font-bold text-blue">CME Credit Claimed</h2>
      <p class="mb-8 text-sm text-center">All yours credits can be viewed in the My Credits</p>
      <Button label="More POEMs" class="p-button-secondary" @click="$router.push('/poems')" />
    </div>
    <template #footer v-if="!lectureCreditFormSubmitted">
      <div class="flex flex-row w-full" :class="[questionIndex === 0 ? '!justify-end' : '', selectedQuestion.type !== 'single' || selectedSubQuestion || selectedQuestion?.options?.find((option) => option.value) ? 'justify-between' : 'justify-start']">
        <Button label="Back" icon="pi pi-arrow-left" class="p-button p-button-secondary p-button-outlined" @click="goBack" v-if="questionIndex !== 0" />
        <Button :label="questionIndex !== questions.length - 1 ? 'Next' : 'Submit'" iconPos="right" icon="pi pi-arrow-right" class="p-button p-button-secondary" @click="goToNext" v-if="selectedQuestion.type !== 'single' || selectedSubQuestion || selectedQuestion?.options?.find((option) => option.value)" :disabled="isLoading" :loading="isLoading" id="claimCmeCreditNextButton" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import TextArea from 'primevue/textarea';
import Rating from 'primevue/rating';
import Message from 'primevue/message';

import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  inject: ['mixpanel'],
  components: { Button, Dialog, TextArea, Rating, Message },
  data() {
    return {
      errors: {},
      lectureCreditFormSubmitted: false,
      isLoading: false,
      displayDialog: false,
      answers: [],
      questionIndex: 0,
      questionIndexProcessStack: [],
      questions: [
        {
          question: 'What is the impact of this information on you or your practice? Please check all that apply',
          options: [
            { question: 'I learned something new', value: false },
            { question: 'I am motivated to learn more', value: false },
            { question: 'I am reassured', value: false },
            { question: 'I am dissatisfied', value: false },
            { question: 'I am reminded of something I already knew', value: false },
            { question: 'This information confirmed I did (am doing) the right thing', value: false },
            { question: 'I disagree with the content of this information', value: false },
          ],
          type: 'multiselect',
        },
        {
          question: 'Was there a problem with the presentation of this information?',
          type: 'single',
          options: [
            { question: 'Yes', value: false },
            { question: 'No', value: false },
          ],
          subQuestions: [
            {
              question: 'Yes',
              options: [
                { question: 'Too much information', value: false },
                { question: 'Not enough information', value: false },
                { question: 'Information poorly written', value: false },
                { question: 'Too technical', value: false },
                { question: 'Other', value: false },
              ],
              otherTextValue: null,
            },
          ],
        },
        {
          question: 'The information is potentially harmful',
          type: 'single',
          options: [
            { question: 'Yes', value: false },
            { question: 'No', value: false },
          ],
          subQuestions: [
            {
              question: 'Yes',
              options: [],
              otherTextValue: null,
            },
          ],
        },
        {
          question: 'Is this information relevant for at least one of your patients?',
          type: 'single',
          options: [
            { question: 'Totally relevant', value: false },
            { question: 'Partially relevant', value: false },
            { question: 'Not relevant', value: false },
          ],
          nextQuestion: [
            {
              answer: 'Totally relevant',
              next: 'Will you use this information for a specific patient?',
            },
            {
              answer: 'Partially relevant',
              next: 'Will you use this information for a specific patient?',
            },
            {
              answer: 'Not relevant',
              next: 'Final Question',
            },
          ],
        },
        {
          question: 'Will you use this information for a specific patient?',
          type: 'single',
          options: [
            { question: 'Yes', value: false },
            { question: 'Possibly', value: false },
            { question: 'No', value: false },
          ],
          subQuestions: [
            {
              question: 'Yes',
              options: [
                { question: 'As a result of this information I will manage this patient differently', value: false },
                { question: 'I had several options for this patient, and I will use this information to justify a choice', value: false },
                { question: 'I thought I knew what to do, and I used this information to be more certain about the management of this patient', value: false },
                { question: 'I used this information to better understand a particular issue related to this patient', value: false },
                { question: 'I will use this information in a discussion with this patient, or with other health professionals about this patient', value: false },
                { question: 'I will use this information to persuade this patient, or to persuade other health professionals to make a change for this patient', value: false },
              ],
              otherTextValue: null,
            },
          ],
          nextQuestion: [
            {
              answer: 'Yes',
              next: 'For this patient, do you expect any health benefits as a result of applying this information?',
            },
            {
              answer: 'Possibly',
              next: 'Final Question',
            },
            {
              answer: 'No',
              next: 'Final Question',
            },
          ],
        },
        {
          question: 'For this patient, do you expect any health benefits as a result of applying this information?',
          type: 'single',
          options: [
            { question: 'Yes', value: false },
            { question: 'No', value: false },
          ],
          subQuestions: [
            {
              question: 'Yes',
              options: [
                { question: 'This information will help to improve this patient‟s health status, functioning or resilience (i.e., ability to adapt to significant life stressors)', value: false },
                { question: 'This information will help to prevent a disease or worsening of disease for this patient', value: false },
                { question: 'This information will help to avoid unnecessary or inappropriate treatment, diagnostic procedures, preventative interventions or a referral, for this patient', value: false },
              ],
              otherTextValue: null,
            },
          ],
        },
        {
          question: 'Final Question',
          type: 'final',
          questions: [
            {
              question: 'Did you perceive any degree of bias in any part of the program?',
              options: [
                { question: 'Yes', value: false },
                { question: 'No', value: false },
              ],
            },
            {
              question: 'Did the activity respect the CQDPCM Code of Ethics (http://cqdpcm.ca/)?',
              options: [
                { question: 'Yes', value: false },
                { question: 'No', value: false },
              ],
            },
          ],
          otherTextValue: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'selectedPoem']),
    selectedQuestion() {
      return this.questions[this.questionIndex];
    },
    selectedSubQuestion() {
      return this.questions[this.questionIndex]?.subQuestions?.find((subQuestion) => subQuestion.question === this.questions[this.questionIndex]?.options?.find((option) => option.value)?.question);
    },
  },
  methods: {
    ...mapActions(['createCmeCredit']),
    ...mapMutations(['setSelectedPoem']),
    openDialog() {
      this.displayDialog = true;
    },
    closeDialog() {
      this.displayDialog = false;
    },
    submitCreateCmeCredit() {
      this.isLoading = true;
      let data = [];
      this.questions
        .filter((question) => {
          return ['multiselect', 'single'].includes(question.type);
        })
        .forEach((question) => {
          let result = {
            question: question.question,
            options: question.options,
            subQuestions: question?.subQuestions,
          };
          data.push(result);
        });

      this.questions
        .filter((question) => {
          return ['final'].includes(question.type);
        })
        .forEach((question) => {
          let result = {
            question: question.question,
            options: question.questions,
            otherTextValue: question.otherTextValue,
          };
          data.push(result);
        });

      this.createCmeCredit({
        feedback_form: JSON.stringify(data),
        user_id: this.loggedInUser.id,
        creditable_id: this.selectedPoem.id,
        creditable_type: 'App\\Models\\Poem',
      })
        .then(() => {
          this.setSelectedPoem({ ...this.selectedPoem, cme_credits: [{ credit: true }] });
          this.isLoading = false;
          this.lectureCreditFormSubmitted = true;
          this.$toast.add({
            severity: 'success',
            summary: 'Feedback Submitted',
            detail: 'Your CME credit has been emailed to you for download',
            life: 5000,
          });
          this.mixpanel.track('CME - Submitted Poem Cme Claim Credit');
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    validateQuestionFilledOut() {
      this.errors = {};
      if (this.selectedQuestion.options) {
        const selectedOption = this.selectedQuestion.options.find((option) => option.value);

        if (!selectedOption) {
          this.errors = {
            question: 'Please select at least one',
          };

          return false;
        }

        if (this.selectedQuestion.subQuestions && this.selectedQuestion.subQuestions.find((subQuestion) => subQuestion.question === selectedOption.question)) {
          const selectedSubQuestion = this.selectedQuestion.subQuestions.find((subQuestion) => subQuestion.question === selectedOption.question);

          if (selectedSubQuestion.options.length > 0) {
            const selectedSubQuestionOption = selectedSubQuestion.options.find((option) => option.value);

            if (!selectedSubQuestionOption) {
              this.errors = {
                subQuestion: 'Please select at least one',
              };

              return false;
            }

            const selectedSubQuestionOther = selectedSubQuestion.options.find((option) => option.question === 'Other' && option.value);

            if (selectedSubQuestionOther && !selectedSubQuestion.otherTextValue) {
              this.errors = {
                otherTextValue: 'Please describe below',
              };
              return false;
            }
          } else if (!selectedSubQuestion.otherTextValue) {
            this.errors = {
              otherTextValue: 'Please describe below',
            };
            return false;
          }
        }
      } else if (this.selectedQuestion.questions) {
        let allQuestionOptionsSelected = true;
        for (let i = 0; i < this.selectedQuestion.questions.length; i++) {
          const selectedOption = this.selectedQuestion.questions[i].options.find((option) => option.value);
          allQuestionOptionsSelected = allQuestionOptionsSelected && !!selectedOption;
          if (!selectedOption) {
            this.errors[`question-${i}`] = 'Please select at least one';
          }
        }

        return allQuestionOptionsSelected;
      }

      return true;
    },
    goToNext() {
      console.log(!this.validateQuestionFilledOut());
      if (!this.validateQuestionFilledOut()) {
        return;
      }
      this.questionIndexProcessStack.push(this.questionIndex);
      if (this.selectedQuestion.nextQuestion) {
        const answer = this.selectedQuestion.options.find((option) => option.value);
        const next = this.selectedQuestion.nextQuestion.find((n) => answer.question === n.answer);
        const nextIndex = this.questions.findIndex((question) => question.question === next.next);
        this.questionIndex = nextIndex;
      } else if (this.questions.length - 1 === this.questionIndex) {
        this.submitCreateCmeCredit();
      } else {
        this.questionIndex++;
      }
    },
    goBack() {
      this.errors = {};
      this.selectedQuestion?.options?.forEach((option) => {
        option.value = false;
      });

      this.selectedQuestion?.questions?.forEach((question) => {
        question.options.forEach((option) => {
          option.value = false;
        });
      });
      this.selectedQuestion?.subQuestions?.forEach((subQuestion) => {
        subQuestion.options.forEach((option) => {
          option.value = false;
        });
        subQuestion.otherTextValue = null;
      });

      if (this.selectedQuestion.otherTextValue) {
        this.selectedQuestion.otherTextValue = null;
      }

      this.questionIndex = this.questionIndexProcessStack.pop();
    },
    yesNoOnClick(selection) {
      this.selectedQuestion?.options?.forEach((option) => {
        option.value = selection.question === option.question;
      });

      if (this.selectedQuestion?.subQuestions) {
        const subQuestions = this.selectedQuestion.subQuestions;
        const selectedSubQuestion = subQuestions.find((subQuestion) => subQuestion.question === selection.question);
        if (!selectedSubQuestion) {
          this.selectedQuestion.subQuestions.forEach((subQuestion) => {
            subQuestion.options.forEach((option) => {
              option.value = false;
            });
            subQuestion.otherTextValue = null;
          });
          this.goToNext();
        }
      } else {
        this.goToNext();
      }
    },
    yesNoFinalOnClick(selectedQuestion, selectedOption) {
      const question = this.selectedQuestion.questions.find((question) => {
        return question.question === selectedQuestion.question;
      });

      question?.options?.forEach((option) => {
        option.value = selectedOption.question === option.question;
      });
    },
  },
  created() {},
  unmounted() {},
};
</script>

<style></style>
