<template>
  <div class="flex flex-col w-full" v-if="oauthRegistered && (currentUrlPath == '/login' || currentUrlPath == '/' || currentUrlPath == '')">
    <!-- <Button class="p-button p-button-outlined w-full p-button-secondary mb-3" id="continueWithWorkEmailBtn" v-if="oauthRegistered == 'microsoft'" @click="loginUsingMicrosoft" v-tooltip.bottom="'Sign up using your work email address that you use in your hospital, university, company or institution. '">
      <i class="fa-solid fa-suitcase-medical" style="font-size: 1.4rem" />
      <div class="w-full font-display font-normal">Continue with Work Email</div>
    </Button> -->
    <Button class="p-button p-button-outlined w-full p-button-secondary mb-3" id="continueWithMicrosoftBtn" v-if="oauthRegistered == 'microsoft'" @click="loginUsingMicrosoft">
      <img src="@/assets/microsoft_logo.png" alt="" style="width: 1.7rem" />
      <div class="w-full font-display font-normal">Continue with Microsoft</div>
    </Button>
    <Button class="p-button p-button-outlined w-full p-button-secondary mb-3" id="continueWithGoogleBtn" v-if="oauthRegistered == 'google'" @click="loginUsingGoogle">
      <img src="@/assets/google_logo.png" alt="" style="width: 1.35rem" />
      <div class="w-full font-display font-normal">Continue with Google</div>
    </Button>
    <Button class="p-button p-button-outlined w-full p-button-secondary" id="continueWithAppleBtn" v-if="oauthRegistered == 'apple'" @click="loginUsingApple" v-tooltip.bottom="'Sign up using your work email address that you use in your hospital, university, company or institution. '">
      <i class="pi pi-apple" style="font-size: 1.4rem" />
      <div class="w-full font-display font-normal">Continue with Apple</div>
    </Button>
  </div>
  <template v-else>
    <div class="flex flex-row w-full justify-evenly">
      <!-- <div class="flex flex-col place-items-center justify-end w-full cursor-pointer" id="workEmailLoginButton">
        <div class="flex flex-col place-items-center border p-3 hover:bg-gray-50" @click="loginUsingMicrosoft">
          <i class="fa-solid fa-suitcase-medical" style="font-size: 1.6rem" />
        </div>
        <div class="text-xs font-display mt-2" v-tooltip.bottom="'Sign up using your work email address that you use in your hospital, university, company or institution. '">Work Email</div>
      </div> -->
      <div class="flex flex-col place-items-center justify-end cursor-pointer" id="microsoftLoginButton">
        <div class="flex flex-col place-items-center border p-3 hover:bg-gray-50" @click="loginUsingMicrosoft">
          <img src="@/assets/microsoft_logo.png" alt="" style="width: 1.6rem; height: 1.6rem" />
        </div>
        <div class="text-xs font-display mt-2">Microsoft</div>
      </div>
      <div class="flex flex-col place-items-center justify-end cursor-pointer" id="googleLoginButton">
        <div class="flex flex-col place-items-center border p-3 hover:bg-gray-50" @click="loginUsingGoogle">
          <img src="@/assets/google_logo.png" alt="" style="width: 1.6rem" />
        </div>
        <div class="text-xs font-display mt-2">Google</div>
      </div>
      <!-- Apple sign-in allows users to not provide email addresses which will cause issues with our user registration.
      But since without Apple sign-in enabled, we can not have SSO in the mobile app, we only want to enable Apple 
      sign in for mobile to avoid Apple sign-ins as much as possible.  -->
      <div class="flex flex-col place-items-center justify-end cursor-pointer" id="appleLoginButton">
        <div class="flex flex-col place-items-center border p-3 hover:bg-gray-50" @click="loginUsingApple">
          <i class="pi pi-apple" style="font-size: 1.6rem" />
        </div>
        <div class="text-xs font-display mt-2">Apple</div>
      </div>
    </div>
  </template>
</template>

<script>
import { mapGetters } from 'vuex';
import pkceChallenge from 'pkce-challenge';
import { Browser } from '@capacitor/browser';

export default {
  props: ['ssoStateProp'],
  data() {
    return {
      currentUrlPath: null,
    };
  },
  components: {},
  created() {
    this.currentUrlPath = location.pathname;
  },
  mounted() {},
  computed: {
    ...mapGetters(['authState']),
    oauthRegistered() {
      return JSON.parse(this.authState.oauthRegistered);
    },
    registrationMetaData() {
      return this.ssoStateProp ? JSON.stringify(this.ssoStateProp) : JSON.stringify({});
    },
  },
  methods: {
    async loginUsingMicrosoft() {
      let url = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
      // Client IDs are not secret and is considered as public identifier: https://www.oauth.com/oauth2-servers/client-registration/client-id-secret/
      let client_id = '1c654663-77ec-4cb8-b304-915e689a8699';
      let scope = 'User.read';
      let response_type = 'code';
      let redirect_uri = `${location.origin}/login`;
      let ssoState = this.registrationMetaData;
      if (this.isNativePlatform()) {
        // In mobile app, the location origin is capacitor:// instead of https:// thus we need to set it manually.
        redirect_uri = `https://${location.host}/login`;
      }
      let response_mode = 'query';
      const challenge = await pkceChallenge(128);
      localStorage.setItem(
        'oauth_properties',
        JSON.stringify({
          oauth_provider: 'microsoft',
          challenge_verifier: challenge.code_verifier,
        })
      );
      let code_challenge = challenge.code_challenge;
      let code_challenge_method = 'S256';
      let uri = encodeURI(`${url}?client_id=${client_id}&response_type=${response_type}&redirect_uri=${redirect_uri}&response_mode=${response_mode}&code_challenge_method=${code_challenge_method}&code_challenge=${code_challenge}&scope=${scope}&state=${ssoState}`);
      if (!this.isNativePlatform()) {
        window.location.href = uri;
      } else {
        try {
          await Browser.open({ url: uri, presentationStyle: 'popover' });
        } catch {
          this.showGenericErrorToast();
        }
      }
    },
    async loginUsingGoogle() {
      let url = 'https://accounts.google.com/o/oauth2/v2/auth';
      let client_id = '282690770981-lf9jg1cnvnlhibdorbe1jcjc1h1fsdc5.apps.googleusercontent.com';
      let scope = 'openid profile email';
      let response_type = 'code';
      let ssoState = this.registrationMetaData;
      let redirect_uri = `${location.origin}/login`;
      if (this.isNativePlatform()) {
        // In mobile app, the location origin is capacitor:// instead of https:// thus we need to set it manually.
        redirect_uri = `https://${location.host}/login`;
      }
      let response_mode = 'query';
      const challenge = await pkceChallenge(128);
      localStorage.setItem(
        'oauth_properties',
        JSON.stringify({
          oauth_provider: 'google',
          challenge_verifier: challenge.code_verifier,
        })
      );
      let code_challenge = challenge.code_challenge;
      let code_challenge_method = 'S256';
      let uri = encodeURI(`${url}?client_id=${client_id}&response_type=${response_type}&redirect_uri=${redirect_uri}&response_mode=${response_mode}&code_challenge_method=${code_challenge_method}&code_challenge=${code_challenge}&scope=${scope}&state=${ssoState}`);
      if (!this.isNativePlatform()) {
        window.location.href = uri;
      } else {
        try {
          await Browser.open({ url: uri, presentationStyle: 'popover' });
        } catch {
          this.showGenericErrorToast();
        }
      }
    },
    async loginUsingApple() {
      let url = 'https://appleid.apple.com/auth/authorize';
      let client_id = 'services.app.virtualhallway.ca';
      let scope = 'name email';
      let response_type = 'code id_token';
      let redirect_uri = `${location.origin}/api/oauth/apple-redirect`;
      let ssoState = JSON.parse(this.registrationMetaData);
      if (this.isNativePlatform()) {
        // In mobile app, the location origin is capacitor:// instead of https:// thus we need to set it manually.
        redirect_uri = `https://${location.host}/api/oauth/apple-redirect`;
      }
      let response_mode = 'form_post';
      const challenge = await pkceChallenge(128);
      localStorage.setItem(
        'oauth_properties',
        JSON.stringify({
          oauth_provider: 'apple',
          challenge_verifier: challenge.code_verifier,
        })
      );
      ssoState.code_challenge = challenge.code_challenge;
      ssoState = JSON.stringify(ssoState);
      let uri = encodeURI(`${url}?client_id=${client_id}&response_type=${response_type}&redirect_uri=${redirect_uri}&response_mode=${response_mode}&scope=${scope}&state=${ssoState}`);
      if (!this.isNativePlatform()) {
        window.location.href = uri;
      } else {
        try {
          await Browser.open({ url: uri, presentationStyle: 'popover' });
        } catch {
          this.showGenericErrorToast();
        }
      }
    },
  },
};
</script>
