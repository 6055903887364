<template>
  <header class="public-navbar">
    <div class="public-navbar-menu">
      <a class="flex items-center" href="https://virtualhallway.ca/">
        <img src="@/assets/Virtual-Hallway-Logo-Nav.png" alt="" class="hidden h-6 md:block" />
        <img src="@/assets/Virtual-Hallway-Logo-Mobile.png" alt="" class="flex h-12 w-12 md:hidden" />
      </a>
      <div class="public-navbar-menu-center"></div>
      <div class="public-navbar-menu-right">
        <ul class="public-navbar-right-list">
          <div class="public-navbar-right-item public-navbar-right-item-text p-2 px-4">
            <h2>Who we help</h2>
            <i class="pi pi-angle-down vh-blue" id="about-angle-down"></i>
          </div>
          <div class="public-navbar-help-dropdown-container flex flex-row">
            <div class="public-navbar-about-dropdown flex flex-col">
              <a class="public-navbar-about-dropdown-item" href="https://virtualhallway.ca/primary-care/">
                <h4 class="public-navbar-about-dropdown-subheading">Family Physicians</h4>
              </a>
              <a class="public-navbar-about-dropdown-item" href="https://virtualhallway.ca/nurse-practitioners/">
                <div class="public-navbar-about-dropdown-subheading">Nurse Practitioners</div>
              </a>
              <a class="public-navbar-about-dropdown-item" href="https://virtualhallway.ca/specialists/">
                <div class="public-navbar-about-dropdown-subheading">Specialists</div>
              </a>
              <a class="public-navbar-about-dropdown-item" href="https://virtualhallway.ca/innovation-leaders/">
                <div class="public-navbar-about-dropdown-subheading">Innovation Leaders</div>
              </a>
            </div>
          </div>
          <div class="public-navbar-right-item public-navbar-right-item-text p-2 px-4">
            <h2>About</h2>
            <i class="pi pi-angle-down vh-blue" id="about-angle-down"></i>
          </div>
          <div class="public-navbar-about-dropdown-container flex flex-row">
            <div class="public-navbar-about-dropdown flex flex-col">
              <a class="public-navbar-about-dropdown-item" href="https://virtualhallway.ca/about/">
                <div class="public-navbar-about-dropdown-subheading">About us</div>
              </a>
              <a class="public-navbar-about-dropdown-item" href="https://virtualhallway.ca/faq/">
                <div class="public-navbar-about-dropdown-subheading">F.A.Q.</div>
              </a>
              <a class="public-navbar-about-dropdown-item" href="https://virtualhallway.ca/blog/">
                <div class="public-navbar-about-dropdown-subheading">Blog</div>
              </a>
              <a class="public-navbar-about-dropdown-item" href="https://virtualhallway.ca/blog/">
                <div class="public-navbar-about-dropdown-subheading">News</div>
              </a>
              <a class="public-navbar-about-dropdown-item" href="https://virtualhallway.ca/contact/">
                <div class="public-navbar-about-dropdown-subheading">Contact Us</div>
              </a>
            </div>
          </div>
          <div class="public-navbar-right-item public-navbar-right-item-text p-2 px-4">
            <h2>Earn CME credits</h2>
            <i class="pi pi-angle-down vh-blue" id="cme-angle-down"></i>
          </div>
          <div class="public-navbar-cme-dropdown-container flex flex-row">
            <div class="public-navbar-cme-dropdown flex flex-col">
              <router-link to="/lectures" class="public-navbar-cme-dropdown-item">
                <div class="public-navbar-cme-dropdown-subheading">CME Lectures</div>
              </router-link>
              <router-link to="/poems" class="public-navbar-cme-dropdown-item">
                <div class="public-navbar-cme-dropdown-subheading">Daily POEMs</div>
              </router-link>
            </div>
          </div>
        </ul>

        <div class="public-navbar-right-item z-10">
          <router-link to="/registration" exact-path>
            <div class="btn btn--main mr-2" id="registrationButton">Get Started</div>
          </router-link>
        </div>
        <div class="public-navbar-right-item z-10">
          <router-link to="/login" class="hidden sm:flex" exact-path>
            <div class="btn btn--main mr">Log In</div>
          </router-link>
        </div>
        <div class="public-navbar-right-item public-navbar-menu-bars text-white ml-4 pt-1">
          <span class="pi pi-bars" @click="$emit('openPublicSideBar')" style="font-size: 2rem"></span>
        </div>
      </div>
    </div>
  </header>
  <!-- <div class="w-full text-center p-3 banner-bg text-sm font-bold">Placeholder banner</div> -->
</template>

<script>
export default {
  emits: ['openPublicSideBar'],
};
</script>

<style scoped>
.banner-bg {
  background-color: #62ccf5;
}

#registrationButton {
  width: 150px;
}
.public-navbar {
  position: relative;
  width: 100%;
  display: block;
  height: 80px;
  font-family: 'Poppins';
  background-color: #2d2840;
}
.public-navbar-menu {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1120px;
  font-family: 'Poppins';
}
.public-navbar-menu-logo,
.public-navbar-menu-center,
.public-navbar-menu-right {
  display: flex;
  align-items: center;
}
.public-navbar-menu-logo {
  justify-content: flex-start;
}
.public-navbar-menu-center {
  flex-grow: 1;
  justify-content: center;
}
.public-navbar-menu-right {
  justify-content: flex-end;
}
.public-navbar-right-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 15;
  width: 100%;
  position: relative;
}

.public-navbar-help-dropdown-container,
.public-navbar-about-dropdown-container,
.public-navbar-cme-dropdown-container {
  position: absolute;
  top: 20px;
  margin-top: 30px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.25s, opacity 300ms;
  z-index: 5;
}
.public-navbar-help-dropdown-container {
  width: 265px;
  margin-left: 0;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.public-navbar-about-dropdown-container {
  width: 200px;
  margin-left: 112.5px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.public-navbar-cme-dropdown-container {
  width: 200px;
  margin-left: 235px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}

.public-navbar-help-dropdown,
.public-navbar-about-dropdown,
.public-navbar-cme-dropdown {
  background-color: var(--vh-white);
  width: 100%;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
  flex-wrap: wrap;
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 10px 13px rgb(119 119 119 / 18%);
}
.public-navbar-help-dropdown {
  align-items: center;
  justify-content: center;
}
.public-navbar-about-dropdown {
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.public-navbar-cme-dropdown {
  align-items: flex-start;
  padding: 20px;
}

.public-navbar-help-dropdown-item,
.public-navbar-about-dropdown-item,
.public-navbar-cme-dropdown-item {
  color: var(--vh-black);
  align-items: flex-start;
}
.public-navbar-help-dropdown-item {
  width: 33.3%;
  height: 100%;
}

.public-navbar-about-dropdown-item {
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.public-navbar-cme-dropdown-item {
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.public-navbar-dropdown-item-icon {
  width: 100px;
  height: 100px;
  padding-top: 50px;
  font-size: 5rem;
}

.public-navbar-dropdown-item-subheading {
  font-weight: 700;
  line-height: 1.1;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;
}

.public-navbar-about-dropdown-subheading {
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
}

.public-navbar-menu-logo img {
  height: 25px;
  width: 172px;
}
.public-navbar-right-item i.pi-angle-down vh-blue {
  transform: rotate(0deg);
  transition-duration: 200ms;
}
.public-navbar-right-item {
  font-weight: 700;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  position: relative;
}

.public-navbar-menu-bars {
  display: none;
}

.public-navbar-right-item-text {
  color: white;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14px;
  cursor: pointer;
}

.public-navbar-right-item-text:hover {
  color: var(--vh-blue);
}
.public-navbar-right-item-text:hover i.pi-angle-down vh-blue {
  transform: rotate(180deg);
  transition-duration: 200ms;
}
.public-navbar-right-item:hover + .public-navbar-about-dropdown-container,
.public-navbar-right-item:hover + .public-navbar-help-dropdown-container,
.public-navbar-right-item:hover + .public-navbar-cme-dropdown-container,
.public-navbar-about-dropdown-container:hover,
.public-navbar-help-dropdown-container:hover,
.public-navbar-cme-dropdown-container:hover {
  visibility: visible;
  opacity: 1;
}

.public-navbar-about-dropdown-item:hover,
.public-navbar-help-dropdown-item:hover,
.public-navbar-cme-dropdown-item:hover {
  background-color: #e8e8e8;
  border-radius: 5px;
}

.public-dropdown-icon {
  font-size: 1.5rem;
}
.vh-blue {
  color: var(--vh-blue);
}

@media (min-width: 1033px) {
}
@media (max-width: 1032px) {
  .public-navbar-right-list {
    display: none;
  }
  .public-navbar-menu-bars {
    display: block;
    cursor: pointer;
  }
}
</style>
