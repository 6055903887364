import axios from 'axios';
import globals from '../../globals';
import moment from 'moment';

const state = {
  cmeCredits: [],
  cmeCertificates: [],
};

const getters = {
  cmeCredits: (state) => {
    return state.cmeCredits;
  },
  cmeCertificates: (state) => {
    return state.cmeCertificates;
  },
};

const actions = {
  getCmeCreditsOfUser(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(data.url, data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setCmeCredits', response.data.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createCmeCredit(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.createCmeCreditUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadCmeCredit(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadCmeCreditUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: 'CME_Credits_' + data.cme_creditable_id + '.pdf' })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCmeCertificatesOfUser(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(data.url, data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setCmeCertificates', response.data.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createCmeCertificate(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.createCmeCertificateUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: 'CME_Certificate.pdf' })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadCmeCertificate(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadCmeCertificateUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: 'CME_Certificate.pdf' })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setCmeCredits(state, data) {
    state.cmeCredits = data;
  },
  setCmeCertificates(state, data) {
    state.cmeCertificates = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
