import axios from 'axios';
import globals from '../../globals';

const state = {
  allProvinces: [],
};

const getters = {
  allProvinces: (state) => {
    return state.allProvinces;
  },
};

// actions
const actions = {
  getAllProvinces(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getAllProvinceUrl(), {
          params: data,
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setAllProvinces', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setAllProvinces(state, data) {
    state.allProvinces = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
