<template>
  <EditProfilePictureDialog :selectedUser="user" :key="user.id" ref="editProfilePictureDialog" />
  <h1 class="text-center text-xl font-bold">Let's Setup Your Account</h1>
  <div :class="animation" class="bg-white rounded border w-full max-w-lg mt-24 flex flex-col items-end">
    <div class="w-full items-center flex flex-col px-8 pb-5 text-start mt-5">
      <div class="mb-5 h-32 w-32 bg-white shadow-xl rounded-full -mt-24 relative cursor-pointer hover:opacity-60" @click="this.$refs.editProfilePictureDialog.toggleModal()" v-tooltip.bottom="'Change Profile Picture.'">
        <img v-bind:src="user.profile.profile_picture_location" class="mx-auto h-32 w-32 rounded-full bg-white object-cover cursor-pointer" v-if="user.profile.profile_picture_location" />
        <div class="absolute top-16 bottom-0 left-0 right-0 flex justify-center items-center rounded-b-full w-32 bg-gradient-to-t from-gray-900 pt-5">
          <i class="fa-solid fa-upload text-white"></i>
        </div>
      </div>
      <div class="w-full text-start mb-5">
        <label for="username" class="auth-input">First Name</label>
        <InputText id="preboardingFirstNameInput" v-model="firstName" class="w-full mt-1" placeholder="e.g., John" />
        <span v-if="errors.firstName" class="text-xs text-red-500">{{ errors.firstName }}</span>
      </div>
      <div class="w-full text-start mb-5">
        <label for="username" class="auth-input">Last Name</label>
        <InputText id="preboardingLastNameInput" v-model="lastName" class="w-full mt-1" placeholder="e.g., Doe" />
        <span v-if="errors.firstName" class="text-xs text-red-500">{{ errors.lastName }}</span>
      </div>
      <div class="flex flex-row justify-end w-full">
        <Button label="Continue" @click="goToNext" class="p-button p-buttom-sm p-button-text" iconPos="right" icon="pi pi-caret-right" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from 'primevue/button';

export default {
  components: {
    Button,
  },
  props: ['propsUser'],
  emits: ['goToPrevious'],

  data() {
    return {
      animation: 'animate-in',
      firstName: null,
      lastName: null,
      user: null,
      openChangeProfilePictureModal: false,
      errors: {},
    };
  },
  computed: {
    ...mapGetters(['isGP', 'isSpecialist', 'allProvinces', 'loggedInUser']),
  },
  created() {
    this.user = this.propsUser;
    this.firstName = this.user.first_name;
    this.lastName = this.user.last_name;
  },
  methods: {
    goToNext() {
      this.errors = {};
      if (!this.firstName) {
        this.errors.firstName = 'Please enter your first name.';
      }
      if (!this.lastName) {
        this.errors.lastName = 'Please enter your last name.';
      }
      if (Object.keys(this.errors).length < 1) {
        this.user.first_name = this.firstName;
        this.user.last_name = this.lastName;
        this.$store.commit('updateLoggedInUser', this.user);
        this.$emit('goToNext', this.user);
      }
    },
    closeThis() {
      this.openChangeProfilePictureModal = false;
    },
  },
  watch: {
    'loggedInUser.profile.profile_picture_location'(val) {
      this.user.profile.profile_picture_location = val;
    },
  },
};
</script>

<style scoped>
.p-float-label label {
  color: rgb(152, 152, 152) !important;
}
</style>
