<template>
  <div class="z-100">
    <div class="layout-topbar shadow flex flex-row items-center justify-between">
      <div class="flex flex-row w-full mb-2.5" :class="isNativePlatform() ? 'mt-0' : ' mt-2.5'">
        <div class="layout-menu-button">
          <Button class="p-button-text" icon="pi pi-bars navbar-icon" @click="onMenuToggle" id="navBarToggleBtn">
            <i class="pi pi-bars navbar-icon text-gray-500"></i>
          </Button>
        </div>

        <div class="layout-topbar-icons w-full flex-row justify-between">
          <div class="flex flex-row">
            <Button @click="toggleNotifications" class="p-button-text" style="overflow: visible" v-if="!isPreboarding(loggedInUser)">
              <i class="pi pi-bell text-gray-500" style="font-size: 1.4rem" v-if="loggedInUser.actions.filter((action) => action.type == 'app_notification' && action.status == 'Active').length > 0" v-badge.danger="loggedInUser.actions.filter((action) => action.type == 'app_notification' && action.status == 'Active').length" id="notification-btn"></i>
              <i class="pi pi-bell text-gray-500" style="font-size: 1.4rem" v-else id="notification-btn"></i>
            </Button>
          </div>
          <OverlayPanel ref="op" appendTo="body" :autoZIndex="false" :showCloseIcon="false" id="overlay_panel" class="notification-overlaypanel shadow-lg ml-3" style="width: 410px" :breakpoints="{ '960px': '80vw', '480px': '95vw' }">
            <NotificationDropdown @toggle="toggleNotifications()" />
          </OverlayPanel>

          <div v-if="!isSpecialist(loggedInUser)">
            <!-- Dummy div to push buttons to end -->
          </div>

          <div class="flex flex-row">
            <div v-if="canSetAvailability(loggedInUser)" class="block">
              <div v-if="tutorialFit() && tutorialStatus == 'click-availability'">
                <div v-if="tutorialStatus != '' && tutorialStatus != 'referral-form-complete'" class="tooltip-background"></div>
                <div v-if="onboardingForm" class="tooltip mr-2">
                  <Button icon="pi pi-calendar" label="Availability" @click="myAvailabilityOnClick" :disabled="!onboardingForm.is_eula_agreed" class="p-button p-button-sm" name="myAvailability"> </Button>
                  <span class="tooltiptext py-4 px-6">
                    <h1 class="text-left text-xl font-bold text-white">Setting Availability</h1>
                    <br />
                    <p class="text-left font-bold text-white">To consult over the phone, your availability is needed. Set your availability by clicking above.</p>
                    <br />
                    <div class="flex flex-row justify-between">
                      <button class="book-a-consult-new-feature-tool-tip-btn text-xs font-bold text-white" @click="skipSettingAvailability" id="skipSpecialistTutorialBtn" v-tooltip.bottom="'Not ready to set your availability? Let’s verify your account.'">SKIP</button>
                      <div class="text-xs font-bold text-white">1/3</div>
                    </div>
                  </span>
                </div>
              </div>
              <div v-else-if="isSpecialist(loggedInUser) && onboardingForm" class="hidden md:flex">
                <Button icon="pi pi-calendar" v-if="isAvailable" label="Availability" @click="myAvailabilityOnClick" :disabled="!onboardingForm.is_eula_agreed" class="p-button p-button-sm" name="myAvailability"> </Button>
                <Button icon="pi pi-exclamation-triangle" v-else label="Availability" @click="myAvailabilityOnClick" :disabled="!onboardingForm.is_eula_agreed" class="p-button p-button-sm p-button-warning" name="myAvailability" v-tooltip.bottom="{ value: 'You currently have no availability, setup now to start receiving consults.' }"> </Button>
              </div>
            </div>
            <div class="block">
              <div class="tooltip ml-1">
                <Button @click="$emit('help-toggle')" class="p-button-text">
                  <i class="pi pi-question-circle navbar-icon text-gray-500"></i>
                </Button>
                <span class="helpTooltiptext py-4 px-6" v-if="showTutorialRelaunchTooltip && tutorialStatus === ''">
                  <h1 class="mb-2 text-left text-lg font-bold text-white">Tutorials</h1>
                  <p class="text-left text-sm font-bold text-white">You can launch the tutorials again from the help center.</p>
                  <br />
                  <div class="flex flex-row justify-between">
                    <button class="book-a-consult-new-feature-tool-tip-btn text-xs font-bold text-white" id="skipSpecialistTutorialBtn" @click="setShowTutorialRelaunchTooltip(false)">Close</button>
                  </div>
                </span>
              </div>
            </div>
            <div v-if="!isOrgUnitHead(loggedInUser)" class="block">
              <Button class="p-button-text" @click="toggleFeedSidebar" id="toggleFeedSidebar" v-if="!isPreboarding(loggedInUser) && !isDemoAccount(loggedInUser)">
                <i class="fas fa-notes-medical navbar-icon text-gray-500"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Button from 'primevue/button';
import NotificationDropdown from '../referral/NotificationDropdown.vue';
import OverlayPanel from 'primevue/overlaypanel';

export default {
  emits: ['menu-toggle', 'feed-toggle', 'help-toggle'],
  components: {
    Button,
    NotificationDropdown,
    OverlayPanel,
    // Menu,
  },
  computed: {
    ...mapGetters([
      'loggedInUser',
      'onboardingForm',
      'getProviderType',
      'canMakeReferrals',
      'canSetAvailability',
      'referrals', //Uncomment when adding notification dropdown
      'tutorialStatus',
      'isOrgUnitHead',
      'orgUnitUsers',
      'isSpecialist',
      'referralToRebook',
      'selectedInteractionType',
      'showTutorialRelaunchTooltip',
      'allReferrals',
      'isPreboarding',
      'isAvailable',
    ]),
    upcomingReferrals: function () {
      return this.allReferrals.filter(function (referral) {
        return referral.status === 'Upcoming' || referral.status === 'In Review';
      });
    },
  },
  data() {
    return {
      disableMakeConsultButton: false,
      toggleHelp: false,
    };
  },
  methods: {
    ...mapMutations(['setTutorialStatus', 'setSelectedInteractionType', 'setShowTutorialRelaunchTooltip']),
    myAvailabilityOnClick() {
      if (this.tutorialStatus === 'click-availability') {
        this.setTutorialStatus('set-availability');
      } else {
        this.setTutorialStatus('');
      }
      this.$router.push({ path: `/schedules` });
    },
    makeReferralOnClick() {
      if (this.tutorialStatus != '') {
        this.setTutorialStatus('select-a-specialty');
      }
      this.$router.push({ path: `/makereferral` });
    },
    onMenuToggle(event) {
      this.$emit('menu-toggle', event);
    },
    toggleFeedSidebar(event) {
      this.$emit('feed-toggle', event);
    },
    toggleNotifications(event) {
      this.$refs.op.toggle(event);
    },
    endTutorial() {
      this.setTutorialStatus('');
    },
    skipSettingAvailability() {
      if (this.tutorialStatus != '') {
        this.updateUserTutorialByName(this.loggedInUser, 'set_availability', true, 'Skipped');
        this.setTutorialStatus('verification');
      }
      this.$router.push({ path: `/verification` });
    },
    toggleInteractionTypeMenu(event) {
      this.$refs.menu.toggle(event);
    },
  },
};
</script>

<style>
.navbar-icon {
  font-size: 1.4rem !important;
}

@media (max-width: 660px) {
  .alert-text {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.slide-up-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-up-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-up-fade-enter-from,
.slide-up-fade-leave-to {
  transform: translatey(200px);
  opacity: 0;
}

.p-overlaypanel-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}
.p-overlaypanel {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  box-shadow: 0px 3px 7px 0px rgb(132 132 132 / 22%) !important;
}
.tooltip-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.tooltip {
  position: relative;
  /* border-bottom: 1px dotted black; */
  z-index: 9;
}

.tooltip .tooltiptext {
  width: 256px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: -5%;
  /* left: 13%; */
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.tooltip .tooltiptext::after {
  transform: rotate(180deg);
  content: '';
  position: absolute;
  top: -5%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.tooltip .helpTooltiptext {
  width: 180px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: -30%;
  /* left: 13%; */
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
  animation: blink 1s 3 alternate;
}

.tooltip .helpTooltiptext::after {
  transform: rotate(180deg);
  content: '';
  position: absolute;
  top: -11%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

/* Learned about blink animation from: https://stackoverflow.com/a/45024196 */
@keyframes blink {
  from {
    background-color: rgb(235, 201, 81);
  }
  to {
    background-color: var(--vh-new-feature-blue);
  }
}

.p-tabview .p-tabview-panels {
  padding: 0 !important;
}

.p-tabview .p-tabview-nav li {
  width: 100%;
}

.p-badge.p-badge-danger {
  background-color: #ff6a78 !important;
}

.fa-user-doctor {
  font-size: 1.2rem !important;
}

.interaction-type-menu-item:hover {
  background-color: #e9eff7;
}
</style>
