<template>
  <section v-if="!loggedInUser" style="background: #2d2840">
    <div class="px-6 py-12 text-left text-white md:px-12">
      <div class="container mx-auto xl:px-4" style="max-width: 1120px">
        <div class="grid items-center gap-12 lg:grid-cols-2">
          <div class="mt-0 mb-2" :class="{ 'pt-12': isNativePlatform() }">
            <h1 class="mb-1 text-2xl font-bold tracking-tight sm:text-3xl xl:text-4xl sm:mb-6">Stay Current, Stay Sharp</h1>
            <p>POEMs are daily, expert-reviewed summaries of the latest clinical research delivered to your inbox. Enhance your knowledge and earn credits - all before your next patient.</p>
          </div>
          <div class="mb-0 lg:block">
            <div class="mx-auto lg:mx-0 max-w-lg animated fade-in-up rounded-2xl p-4 sm:p-10 bg-white text-black">
              <SubscribeToPoems @successfullySubscribedToPoems="successfullySubscribedToPoems" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-else style="background: #2d2840">
    <div class="px-4 pb-5 text-left text-white md:px-12" :class="isNativePlatform() ? 'pt-32' : 'pt-24'">
      <div class="w-full flex flex-col lg:flex-row mx-auto justify-center items-center">
        <div class="mt-0 mb-2 max-w-lg mr-0 lg:mr-8">
          <h1 class="mb-1 text-2xl font-bold tracking-tight sm:text-3xl xl:text-4xl sm:mb-6">Stay Current, Stay Sharp</h1>
          <p>POEMs are daily, expert-reviewed summaries of the latest clinical research delivered to your inbox. Enhance your knowledge and earn credits - all before your next patient.</p>
        </div>
        <div class="mb-0 lg:block mt-8 lg:mt-0" v-if="!hasNotificationEnabled(loggedInUser, 'poems', 'Email')">
          <div class="mx-auto lg:mx-0 max-w-lg animated fade-in-up rounded-2xl p-4 sm:p-10 bg-white text-black w-full">
            <SubscribeToPoems @successfullySubscribedToPoems="successfullySubscribedToPoems" />
          </div>
        </div>
        <div v-else class="mt-8 lg:mt-0 hidden lg:block"><img src="@/assets/Registration-BG-3.webp" class="w-full shadow-lg image-alt-rounded-corners h-80 mb-2" alt="" /></div>
      </div>
    </div>
  </section>
  <section id="menu_bar" class="flex flex-col items-center bg-white md:px-4 w-full" style="border-bottom: 1px solid #c9c9c9" v-if="loggedInUser">
    <div class="w-full flex justify-start items-center">
      <Menubar :model="menuBarItems" class="w-full md:-ml-4">
        <template #item="{ item, props }">
          <a v-ripple class="flex align-items-center" v-bind="props.action">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
          </a>
        </template>
      </Menubar>
      <div class="items-center justify-center h-full flex flex-row">
        <Button :icon="currentSort.sortOrder === 'desc' ? 'pi pi-arrow-down' : 'pi pi-arrow-up'" class="menubar-button" :label="currentSort.sortOrder === 'desc' ? 'Most Recent' : 'Oldest'" @click="onSort({ sortField: 'delivery_datetime', sortOrder: currentSort.sortOrder === 'desc' ? 'asc' : 'desc' })"></Button>
        <Dropdown placeholder="Filter by Specialty" :options="tags" optionLabel="name" optionValue="id" v-model="selectedTag" showClear @change="onFilterChange" class="p-inputtext-sm" v-if="tags.length > 0"></Dropdown>
      </div>
    </div>
  </section>
  <section id="lecture_search_bar" class="flex flex-col items-center bg-white md:px-4 w-full" style="border-bottom: 1px solid #c9c9c9">
    <div class="w-full flex flex-col md:flex-row items-start justify-start md:items-center px-2 py-2" style="max-width: 1120px">
      <IconField iconPosition="left" class="w-full">
        <InputIcon class="pi pi-search"> </InputIcon>
        <InputText v-model="searchPoemsValue" placeholder="Search POEMs" class="w-full p-inputtext-sm" style="padding-left: 2.5rem; width: 100%" maxLength="255" @input="onFilterChangeDebounce" />
      </IconField>
    </div>
  </section>

  <div :class="isNativePlatform() ? 'px-5 py-4' : 'main-inner-wrap vld-parent'" ref="poemsContainer">
    <div class="w-full px-0 sm:px-4 relative" style="max-width: 1120px" :class="{ 'mt-8': isNativePlatform() }">
      <div class="flex flex-row overflow-x-scroll w-full -mt-12 no-scrollbar"></div>
      <div v-if="poems.length > 0" class="mt-2">
        <div v-auto-animate class="flex flex-col items-center animated fade-in-up animation-delay">
          <div v-for="poem in poems" :key="poem.id" class="w-full max-w-2xl bg-gray-50 rounded-xl text-left px-6 sm:px-8 py-3 my-2 shadow-sm hover:-translate-y-0.5 hover:border-gray-500 transform transition duration-500 ease-in-out cursor-pointer border border-gray-300 relative" @click="selectPoem(poem)" :id="`poem-${poem.id}`">
            <h3 class="font-semibold text-gray-900">{{ poem.title }}</h3>
            <p class="text-sm mt-2">{{ poem.bottom_line.substring(0, 155) }}.....</p>
            <div class="flex flex-row">
              <div class="flex flew-row items-center border rounded-xl mt-2 px-3 py-1 justify-center mr-2 hover:bg-gray-100 hover:border-gray-400" v-on:click.stop @click="togglePoemLikeOnSubmit(poem)" :id="`poem-like-button-${poem.id}`">
                <i class="pi pr-3" :class="poem?.likes?.length > 0 ? 'pi-thumbs-up-fill' : 'pi-thumbs-up'"></i>
                <span class="font-base text-sm">{{ poem.likes_count }}</span>
              </div>
              <div class="hidden sm:flex flew-row items-center border rounded-xl mt-2 px-3 py-1 justify-center hover:bg-gray-100 hover:border-gray-400 mr-2" :id="`poem-comment-button-${poem.id}`">
                <i class="pi pi-comment pr-2"></i>
                <span class="font-base text-sm">{{ poem.comments_count }}</span>
              </div>
              <div class="flex flew-row items-center border rounded-xl mt-2 px-3 py-1 justify-center hover:bg-gray-100 hover:border-gray-400 mr-2" v-on:click.stop @click="togglePoemBookmarkOnSubmit(poem)" :id="`poem-bookmark-button-${poem.id}`">
                <i class="pi" :class="poem?.bookmarks?.length > 0 ? 'pi-bookmark-fill' : 'pi-bookmark'"></i>
              </div>
              <div class="flex flew-row items-center border rounded-xl mt-2 px-3 py-1 justify-center hover:bg-gray-100 hover:border-gray-400 mr-2" v-on:click.stop @click="shareOnClick($event, poem)" :id="`poem-share-button-${poem.id}`">
                <i class="pi pi-send pr-0 sm:pr-2"></i>
                <span class="font-base text-sm hidden sm:block">Share</span>
              </div>
              <div v-if="poem?.cme_credits?.length > 0" class="flex flew-row items-center rounded-xl mt-2 px-3 py-1 bg-secondary justify-center text-white">
                <i class="fas fa-award text-white mr-0 sm:mr-2" style="font-size: 0.8rem" />
                <span class="font-base text-sm hidden sm:block">Claimed</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!isLoading" class="relative flex flex-col justify-center items-center w-full mt-10">
        <h1 class="mb-2 text-2xl">No Results</h1>
        <Button label="Clear Filters" @click="clearFilters" class="p-button-xs" style="width: 200px" />
      </div>
      <div v-if="isLoading">
        <div v-auto-animate class="flex flex-col items-center animated fade-in-up animation-delay">
          <div v-for="i in [1, 2, 3]" :key="i" class="w-full max-w-2xl bg-gray-50 rounded-xl text-left px-8 py-3 my-2 shadow-sm border border-gray-300">
            <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
            <Skeleton height="4rem" class="mb-2" borderRadius="16px"></Skeleton>
            <div class="flex flex-row">
              <Skeleton width="5rem" borderRadius="16px" class="mb-2 mr-2" height="2rem"></Skeleton>
              <Skeleton width="5rem" borderRadius="16px" class="mb-2 2 mr-2" height="2rem"></Skeleton>
              <Skeleton width="5rem" borderRadius="16px" class="mb-2 2 mr-2" height="2rem"></Skeleton>
              <Skeleton width="5rem" borderRadius="16px" class="mb-2 2 mr-2" height="2rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
      <transition name="slide-up-fade">
        <div v-auto-animate class="fixed right-10 w-12 h-12 rounded-full bg-secondary cursor-pointer flex justify-center items-center" :class="isNativePlatform() ? 'bottom-24' : 'bottom-10'" @click="scrollToTop" v-if="displayScrollToTopButton">
          <i class="pi pi-angle-up text-white text-xl" style="font-size: 1.4rem"></i>
        </div>
      </transition>
    </div>
  </div>
  <section id="lecture_value_props" v-if="!loggedInUser" class="bg-white">
    <div class="max-w-screen-xl pb-8 pt-4 mx-auto" style="max-width: 1120px">
      <div class="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2 lg:grid-cols-3">
        <div class="block p-8">
          <i class="mx-auto pi pi-sign-in text-primary" style="font-size: 2rem"></i>
          <h2 class="mt-4 text-xl font-bold text-primary">Sign Up</h2>
        </div>
        <div class="block p-8">
          <i class="mx-auto pi pi-send text-primary" style="font-size: 2rem"></i>
          <h2 class="mt-4 text-xl font-bold text-primary">Receive Daily POEMs</h2>
        </div>
        <div class="block p-8">
          <i class="mx-auto pi pi-book text-primary" style="font-size: 2rem"></i>
          <h2 class="mt-4 text-xl font-bold text-primary">Read, Learn, and Earn Credits</h2>
        </div>
      </div>
    </div>
  </section>
  <section v-if="!loggedInUser" style="background: #2d2840">
    <div class="px-6 py-12 text-left text-white md:px-12">
      <div class="container mx-auto" style="max-width: 1120px">
        <div class="">
          <div class="mb-0 lg:block">
            <div class="mx-auto max-w-lg animated fade-in-up rounded-2xl p-4 sm:p-10 bg-white text-black">
              <SubscribeToPoems />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Modal ref="subscribeToPoemsModal" class="mx-auto">
    <template v-slot:body>
      <div class="flex flex-col justify-center items-center sm:w-[500px] max-w-xl px-6 py-8 relative" id="subscribeToPoemsModal">
        <i class="pi pi-times text-gray-400 top-3 right-3 absolute cursor-pointer p-1.5" @click="$refs.subscribeToPoemsModal.closeModal()" style="font-size: 1rem" id="closeSubscribeToPoemsModalButton"></i>
        <div class="w-full max-w-lg text-gray-600 text-md flex flex-col px-2 text-center">
          <SubscribeToPoems @successfullySubscribedToPoems="successfullySubscribedToPoems" :selectedPoem="selectedPoem" />
        </div>
      </div>
    </template>
  </Modal>
  <Modal ref="registrationModal" class="mx-auto">
    <template v-slot:body>
      <div class="animated fade-in-left animated-delay-3 px-6 py-8 w-full sm:w-[576px] relative" id="registrationModal">
        <i class="pi pi-times text-gray-400 top-3 right-3 absolute cursor-pointer p-1.5" @click="$refs.registrationModal.closeModal()" style="font-size: 1rem" id="closeRegistrationModalButton"></i>
        <div class="flex flex-col justify-center items-center w-full">
          <h1 class="text-xl font-bold mb-1">Finish Signing up</h1>
          <p class="w-full text-sm mb-3">View all POEMs and claim credits!</p>
          <div class="w-full max-w-lg text-gray-600 text-md flex flex-col text-center">
            <RegistrationForm :queryProps="{ loginRedirect: selectedPoem ? `/poem/${selectedPoem.id}` : '/poems' }" />
            <div class="text-sm font-bold font-display mt-4">Already have an account? <span class="cursor-pointer text-primary">Log in here</span>&nbsp;</div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
  <Menu ref="menu" style="width: 210px" :model="menuItems" :popup="true"> </Menu>
</template>

<script>
import globals from '../../../globals.js';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Loading from 'vue-loading-overlay';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Skeleton from 'primevue/skeleton';
import Modal from '@/components/misc/Modal.vue';
import RegistrationForm from '@/views/public/registration/registrationForm/RegistrationForm.vue';
import { Share } from '@capacitor/share';
import Menu from 'primevue/menu';

import { mapGetters, mapActions } from 'vuex';
export default {
  components: {
    Button,
    Loading,
    Dialog,
    Dropdown,
    InputText,
    Skeleton,
    Modal,
    RegistrationForm,
    Menu,
  },

  data() {
    return {
      displayScrollToTopButton: false,
      showRegistration: false,
      menuBarItems: [
        // {
        //   label: 'My Credits',
        //   icon: 'fas fa-award',
        //   command: () => {
        //     this.$router.push('/poems-cme-credits');
        //   },
        // },
        // {
        //   label: 'Popular',
        //   icon: 'pi pi-fw pi-star',
        //   command: () => {
        //     this.scrollToOnDemandAndSort();
        //   },
        // },
        {
          label: 'Saved',
          icon: 'pi pi-bookmark',
          command: () => {
            this.bookmarked = !this.bookmarked;
            this.onFilterChange();
          },
        },
        {
          label: 'Unclaimed',
          icon: 'pi pi-star',
          command: () => {
            this.unclaimed = !this.unclaimed;
            this.onFilterChange();
          },
        },
      ],
      menuItems: [],
      filterValue: '',
      isLoading: false,
      tags: [],
      selectedTag: null,
      searchPoemsValue: null,
      bookmarked: false,
      unclaimed: false,
      mainUrl: globals.APIs.getPoemsUrl(),
      currentUrl: globals.APIs.getPoemsUrl() + '?page=1',
      currentFilters: [],
      currentSort: { sortField: 'delivery_datetime', sortOrder: 'desc' },
      poems: [],
      poemsPaginator: null,
      debounceTimer: null,
      selectedPoem: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['getPoems', 'getPoemTags', 'togglePoemBookmark', 'togglePoemLike', 'createUserAnalytic']),
    selectPoem(poem) {
      this.selectedPoem = poem;
      if (this.loggedInUser) {
        this.$router.push(`/poem/${poem.id}`);
      } else {
        this.$refs.subscribeToPoemsModal.openModal();
      }
    },
    clearFilters() {
      this.selectedTag = null;
      this.searchPoemsValue = null;
      this.bookmarked = false;
      this.unclaimed = false;
      let url = this.mainUrl + '?page=1';
      this.currentUrl = url;
      this.currentSort = { sortField: 'delivery_datetime', sortOrder: 'desc' };
      this.currentFilters = [];
      this.getPoemsMethod();
    },
    getPoemsMethod() {
      this.isLoading = true;
      let pageSize = 25;
      this.getPoems({ url: this.currentUrl, filters: this.currentFilters, sort: this.currentSort, page_size: pageSize })
        .then((data) => {
          if (this.currentUrl[this.currentUrl.length - 1] === '1') {
            this.poemsPaginator = data;
            this.poems = data.data;
          } else {
            this.poemsPaginator = data;
            this.poems = this.poems.concat(data.data);
          }

          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    onFilterChange() {
      this.resetDebounceTimer();
      this.currentFilters = [];
      if (this.selectedTag) {
        this.currentFilters.push({ name: 'tags', value: this.selectedTag });
      }
      if (this.searchPoemsValue) {
        this.currentFilters.push({ name: 'title', value: this.searchPoemsValue });
      }
      if (this.bookmarked) {
        this.currentFilters.push({ name: 'bookmarked' });
      }
      if (this.unclaimed) {
        this.currentFilters.push({ name: 'unclaimed' });
      }

      let url = this.mainUrl + '?page=1';
      this.currentUrl = url;
      this.getPoemsMethod();
    },
    onSort(event) {
      this.currentSort = { sortField: event.sortField, sortOrder: event.sortOrder };
      let url = this.mainUrl + '?page=1';
      this.currentUrl = url;
      this.getPoemsMethod();
    },
    handleScroll() {
      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollPosition = scrollTop / scrollHeight;

      this.displayScrollToTopButton = scrollTop >= 500;
      if (scrollPosition >= 0.75 && !this.isLoading && this.currentUrl[this.currentUrl.length - 1] !== this.poemsPaginator.last_page_url[this.poemsPaginator.last_page_url.length - 1]) {
        if (!this.loggedInUser) {
          return;
        }
        const currentPage = parseInt(this.currentUrl[this.currentUrl.length - 1]);
        this.currentUrl = this.mainUrl + `?page=${currentPage + 1}`;
        this.getPoemsMethod();
      }
    },
    resetDebounceTimer() {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = null;
      }
    },
    onFilterChangeDebounce() {
      this.resetDebounceTimer();
      this.debounceTimer = setTimeout(() => {
        this.onFilterChange();
      }, 300);
    },
    togglePoemBookmarkOnSubmit(poem) {
      if (!this.loggedInUser) {
        this.selectedPoem = poem;
        this.$refs.subscribeToPoemsModal.openModal();
        return;
      }
      if (poem.bookmarks.length > 0) {
        poem.bookmarks = [];
      } else {
        poem.bookmarks.push(this.loggedInUser);
      }
      this.togglePoemBookmark({ poem_id: poem.id })
        .then(() => {})
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    togglePoemLikeOnSubmit(poem) {
      if (!this.loggedInUser) {
        this.selectedPoem = poem;
        this.$refs.subscribeToPoemsModal.openModal();
        return;
      }

      if (poem.likes.length > 0) {
        poem.likes = [];
        poem.likes_count--;
      } else {
        poem.likes.push(this.loggedInUser);
        poem.likes_count++;
      }
      this.togglePoemLike({ poem_id: poem.id })
        .then(() => {})
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    async shareOnClick(event, poem) {
      let hostname = window.location.hostname;
      if (hostname === 'localhost') {
        hostname = 'localhost:8080';
      }
      const url = `https://app.virtualhallway.ca/poem/${poem.id}`;

      if (this.isNativePlatform()) {
        try {
          await Share.share({
            title: 'Virtual Hallway POEMs',
            text: poem.title,
            url,
            dialogTitle: 'Share POEMs Link',
          });
        } catch (e) {}
      } else {
        this.setShareLinks(poem);
        this.$refs.menu.toggle(event);
      }
    },
    setShareLinks(poem) {
      let hostname = window.location.hostname;
      if (hostname === 'localhost') {
        hostname = 'localhost:8080';
      }
      const baseUrl = `https://app.virtualhallway.ca/poem/${poem.id}`;
      const message = `Virtual Hallway Presents - POEM - ${poem.title}`;
      this.menuItems = [
        {
          label: 'Facebook',
          icon: 'pi pi-facebook',
          command: () => {
            const url = encodeURI(`https://www.facebook.com/share_channel/?link=${baseUrl}&quote=${message}&source_surface=external_reshare&display&hashtag=#CME`);
            window.open(url, '_blank');
          },
        },
        {
          label: 'LinkedIn',
          icon: 'pi pi-linkedin',
          command: () => {
            const url = encodeURI(`https://www.linkedin.com/sharing/share-offsite/?url=${baseUrl}&title=${message}`);
            window.open(url, '_blank');
          },
        },
        {
          label: 'Twitter',
          icon: 'pi pi-twitter',
          command: () => {
            const url = encodeURI(`https://x.com/intent/post?text=${message}&url=${baseUrl}&hashtags=#CME`);
            window.open(url, '_blank');
          },
        },
        {
          label: 'Copy Link',
          icon: 'pi pi-link',
          command: () => {
            this.copyToClipboard(poem);
          },
        },
      ];
    },
    copyToClipboard(poem) {
      let hostname = window.location.hostname;
      if (hostname === 'localhost') {
        hostname = 'localhost:8080';
      }
      const url = `${hostname}/poem/${poem.id}`;

      navigator.clipboard.writeText(url);
      this.$toast.add({
        severity: 'success',
        summary: 'Link Copied',
        life: 2000,
      });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    successfullySubscribedToPoems() {
      this.$refs.subscribeToPoemsModal.closeModal();
      if (!this.loggedInUser) {
        this.$refs.registrationModal.openModal();
      }
    },
  },
  created() {
    this.currentSort = { sortField: 'delivery_datetime', sortOrder: 'desc' };
    this.getPoemsMethod();
    this.getPoemTags()
      .then((data) => {
        this.tags = data;
      })
      .catch(() => {});

    let data = {
      analyticable_type: 'App\\Models\\Poem',
      analyticable_id: null,
      description: 'User viewed poems',
      tags: ['Poems'],
      meta_data: JSON.stringify({ url_query: this.$route.fullPath }),
    };
    this.createUserAnalytic(data)
      .then(() => {})
      .catch(() => {});
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    loggedInUser(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.$refs.registrationModal.closeModal();
        this.getPoemsMethod();
      }
    },
  },
};
</script>

<style>
.p-menubar-root-list {
  min-width: 230px;
}
.dark-blue {
  color: #5b76a2;
}
.subheading-blue {
  color: #859dc4;
}
.video-background {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: gray;
}
.cme-label {
  color: white;
  top: 0;
  left: 0;
  position: absolute;
  border-top-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.cme-live-label {
  color: white;
  background-color: #ef5164;
  top: 0;
  left: 0;
  position: absolute;
  border-top-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  z-index: 2;
}
.credit-claimed-label {
  color: white;
  bottom: 1.5rem;
  right: 0;
  position: absolute;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.image-alt-rounded-corners {
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
}
.p-menubar {
  background: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 0.8rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color: #394353;
}

.p-menubar .p-menubar-root-list > .p-menuitem:hover {
  background: #f0f2f4;
  border-radius: 5px;
}
.p-menuitem {
  margin-bottom: 0;
}
@media (max-width: 960px) {
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    flex-direction: row-reverse;
    justify-content: start;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link > svg,
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link > .pi {
    margin-left: 0.5rem;
  }
}
.menubar-button {
  border: none;
  font-family: 'Poppins';
  font-size: 0.8rem;
  border: none;
  font-weight: 600;
  color: rgb(57, 67, 83);
  background: #ffffff;
  text-wrap: nowrap;
  width: 130px;
  text-align: left;
}

.menubar-button:enabled:hover {
  background: #f0f2f4;
  color: rgb(57, 67, 83);
}
</style>
