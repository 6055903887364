<template>
  <div :class="animation" class="bg-white rounded border p-8 w-full max-w-lg">
    <form method="post" @submit.prevent="submit">
      <h1 v-if="this.propsUser.role == 'gp'" class="text-left text-xl font-bold mb-4">What is your license number?</h1>
      <h1 v-if="this.propsUser.role == 'specialist'" class="text-left text-xl font-bold mb-4">Enter your license details and specialty</h1>
      <h1 v-if="this.propsUser.role == 'org_unit_head'" class="text-left text-blue text-xl font-bold mb-4">Enter your Practice Group details</h1>
      <template v-if="this.propsUser.role == 'gp' || this.propsUser.role == 'specialist'">
        <h4 class="text-left text-gray-600 text-sm mb-6" v-if="!hasProvinceOfPractice(propsUser, ['ON'])">Please enter your license so we can verify that you are in good standing with the college.</h4>
        <h4 class="text-left text-gray-600 text-sm mb-6" v-else>Please enter your OHIP Billing Number.</h4>
      </template>
      <h4 v-if="this.propsUser.role == 'org_unit_head'" class="text-left text-gray-600 text-sm mb-6">Please enter your clinic name so we can link your account.</h4>
      <div class="mb-8 mt-8 w-full" v-if="this.propsUser.role !== 'org_unit_head'">
        <span class="w-full">
          <template v-if="!hasProvinceOfPractice(propsUser, ['ON'])">
            <label for="cpsns" class="auth-input">Practitioner License Number</label>
            <InputText type="text" name="cpsns" id="cpsns" v-model="user.cpsns" required class="w-full p-inputtext-lg" maxLength="255" />
          </template>
          <template v-else>
            <div class="flex flex-row">
              <label for="ohip_number" class="auth-input pr-1">OHIP Billing Number</label>
              <i class="pi pi-question-circle" v-tooltip="'This is your 6 digit billing number. The full billing number from OHIP consists of 3 parts: \n 1. Group number: this is a 4 digit alpha numeric code. \n 2. Billing number: these 6 digits are the main component of your provider number. This is what is associated with OHIP claims, when you are identified as a referring physician for a consultation to another MD. \n 3. Specialty code: every medical specialty has a 2 digit code'"></i>
            </div>
            <InputText type="text" name="ohip_number" id="ohip_number" v-model="user.ohip_number" required class="w-full p-inputtext-lg" maxLength="6" minLength="6" />
          </template>
        </span>
      </div>
      <div class="mb-8 mt-8 w-full-2" v-if="this.propsUser.role == 'org_unit_head'">
        <span class="w-full" v-tooltip.left="'Please input the name of the Group Practice you wish to join.'">
          <label for="orgUnit" class="auth-input">Group Practice Name</label>
          <InputText type="text" name="orgUnit" id="orgUnit" v-model="user.org_unit" class="w-full p-inputtext-lg" maxLength="255" required />
        </span>
      </div>

      <div v-if="this.propsUser.role == 'specialist'" class="mb-8">
        <div class="px-0 text-left" v-tooltip.bottom="'If you can not find your specialty, select Other and we will contact you to add your specialty once you register.'">
          <label for="specialty" class="auth-input error" v-if="errors.specialty_ids">{{ errors.specialty_ids }}</label>
          <label for="specialty" class="auth-input" v-else>What type of specialist are you? Select all that apply.</label>
          <MultiSelect :class="['w-full', { 'p-invalid': errors.specialty_ids }]" v-model="user.specialty_ids" :options="allSpecialties" name="specialty" id="specialty" optionLabel="name" :required="true" :showToggleAll="false" :filter="true" display="chip" emptyMessage="Please Select a Specialty" />
        </div>
      </div>
      <div class="col-start-1 col-end-3 mb-2" v-if="user.specialty_ids && user.specialty_ids.find((specialty) => specialty.name === 'Other')">
        <span class="p-float-label">
          <InputText type="text" name="other_specialty" id="other_specialty" v-model="user.other_specialty" required class="w-full" maxLength="255" />
          <label for="other_specialty">Enter Your Specialty If Not Listed Above </label>
        </span>
      </div>

      <div class="mb-8">
        <label for="phoneNumber" class="auth-input" v-if="!phoneNumberError">Cell Number <span class="error">*&nbsp;</span></label>
        <label for="phoneNumber" class="auth-input" v-else>Phone number must be a 10 digit number</label>
        <InputMask type="tel" mask="(999)-999-9999" id="phoneNumberInput" name="phoneNumber" v-model="phoneNumberWitMask" maxlength="16" required :autoClear="false" :class="['p-inputtext-lg', { 'p-invalid': phoneNumberError }]" />
      </div>

      <div v-if="this.propsUser.role != 'org_unit_head'" class="mb-8">
        <div class="px-0 text-left">
          <label for="preferred_emr" class="auth-input">What EMR/EHR do you use? (Optional)</label>
          <Dropdown class="p-inputtext-lg w-full" v-model="user.preferred_emr" :options="emrOptions" id="preferred_emr" :filter="false" emptyMessage="Select your EMR/EHR" />
        </div>
      </div>

      <span class="registration-error" v-if="specialtyError"> Type of Specialty is Required </span>
      <div class="flex flex-row justify-between items-center align-center">
        <Button label="Previous" @click="goToPrevious()" iconPos="left" class="p-button-text p-buttom-sm p-button-secondary" icon="pi pi-caret-left" />
        <div v-if="user.role == 'specialist'">
          <Button label="Continue" type="submit" id="submit" class="p-button p-buttom-sm" iconPos="right" icon="pi pi-arrow-right" />
        </div>
        <div v-else>
          <Button label="Complete" type="submit" id="submit" class="p-button p-buttom-sm" iconPos="right" icon="pi pi-check" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';

export default {
  components: {
    Button,
    MultiSelect,
    InputText,
  },
  props: ['loggedInUser', 'propsUser'],
  emits: ['goToNext', 'goToPrevious'],

  data() {
    return {
      animation: 'animate-in',
      user: this.loggedInUser,
      specialty_ids: [],
      cpsns: '',
      providerTypeValue: null,
      provinceValue: '',
      specialtyError: null,
      phoneNumberError: false,
      phoneNumberWitMask: '',
      errors: {},

      emrOptions: ['Accuro', 'Epic', 'Oracle Cerner', 'Oscar', 'Medaccess', 'Cerner', 'MEDITECH', 'None', 'PS Suite EMR', 'Wolf'],
    };
  },
  computed: {
    ...mapGetters(['allProvinces', 'allSpecialties', 'actingUser', 'hasProvinceOfPractice']),
  },
  methods: {
    ...mapActions(['getSpecialties']),
    validatePhoneNumber() {
      this.user.phone_number = this.phoneNumberWitMask.replace(/[^0-9]/g, '');
      if (!/[0-9]/.test(this.user.phone_number) || this.user.phone_number.length != 10) {
        this.phoneNumberError = true;
        this.errors.phone_number = 'Please enter a 10 digit phone number';
      } else {
        this.phoneNumberError = false;
      }
    },
    validate() {
      this.errors = {};
      this.validatePhoneNumber();
      if (this.user.role === 'specialist' && (!this.user.specialty_ids || this.user.specialty_ids.length === 0)) {
        this.errors.specialty_ids = 'Please select a specialty';
      }
    },
    submit() {
      this.validate();
      if (Object.keys(this.errors).length === 0) {
        this.setLicense();
      }
    },
    goToPrevious() {
      this.$emit('goToPrevious', this.user);
    },
    setLicense() {
      this.user.emr = this.user.emr;
      if (this.user.role != 'specialist') {
        this.user.status = 'Onboarding'; //Not sure if this is necessary, I think the user status is returned from the backend
        this.user.is_submitted = true;
      }
      this.$emit('goToNext', this.user);
    },
  },
  created() {
    this.provinceValue = this.loggedInUser.practice_province;
    this.phoneNumberWitMask = this.user.phone_number;

    if (this.provinceValue && this.user.role == 'specialist') {
      this.getSpecialties({
        requesting_user_id: this.loggedInUser.id,
        province_name: this.provinceValue.name,
        interaction_type_names: ['phone'],
        for_referral: 0,
      })
        .then(() => {})
        .catch(() => {
          this.showGenericErrorToast();
        });
    }
  },
};
</script>

<style>
.p-multiselect-label-container {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
