<template>
  <div :class="animation" class="bg-white rounded border p-8 w-full max-w-lg">
    <h1 class="text-left text-xl font-bold mb-3">Welcome to Virtual Hallway</h1>
    <h4 class="text-left text-gray-600 text-sm mb-6"></h4>
    <div id="set-intention-consult-button" @click="goToNext" class="select-button-small border-hover--blue font-display mb-5">
      <span>I'd like to request or provide consults</span>
      <i class="pi pi-caret-right" />
    </div>
    <div id="set-intention-cme-button" @click="goToLectures" class="select-button-small border-hover--blue font-display mb-5">
      <span>I'm here to watch CME lectures and earn CME credits </span>
      <i class="pi pi-caret-right" />
    </div>
    <div id="set-intention-poems-button" @click="setPoemsUserGoToNext" class="select-button-small border-hover--blue font-display mb-5">
      <span>I'm here to read POEMs and earn CME credits </span>
      <i class="pi pi-caret-right" />
    </div>
    <div class="flex flex-row justify-left items-center align-center">
      <Button label="Previous" @click="goToPrevious()" iconPos="left" class="p-button-text p-buttom-sm p-button-secondary" icon="pi pi-caret-left" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from 'primevue/button';

export default {
  components: {
    Button,
  },
  props: ['loggedInUser'],
  emits: ['goToPrevious'],

  data() {
    return {
      animation: 'animate-in',
      user: this.loggedInUser,
    };
  },
  computed: {
    ...mapGetters(['isGP', 'isSpecialist', 'allProvinces']),
  },
  created() {
    this.user.role = '';
  },
  methods: {
    goToNext() {
      this.$emit('goToNext', this.user);
    },
    goToLectures() {
      this.user.role = 'cme';
      this.user.intention = 'lectures';
      this.$store.commit('updateLoggedInUser', this.user);
      this.$emit('goToNext', this.user);
    },
    goToPrevious() {
      this.$emit('goToPrevious', this.user);
    },
    setPoemsUserGoToNext() {
      this.user.intention = 'poems';
      this.user.role = 'cme';
      this.$store.commit('updateLoggedInUser', this.user);
      this.$emit('goToNext', this.user);
    },
  },
};
</script>

<style></style>
