<template>
  <div :class="animation" class="bg-white rounded border p-8 w-full max-w-lg">
    <div class="flex row justify-between items-center">
      <h1 class="text-left text-xl font-bold">Where do you practice?</h1>
      <i class="pi pi-question-circle text-gray-400" style="font-size: 1.2rem" v-tooltip.left="'If you are licensed in multiple provinces, pick the province you wish to use Virtual Hallway in.'" />
    </div>
    <Message v-if="isCme(this.actingUser)" :closable="false" class="text-left">If you are a licensed clinician, you can complete this process to book consults through Virtual Hallway</Message>
    <h4 class="text-left text-gray-600 text-sm mb-6"></h4>
    <div class="mb-8">
      <div class="px-0 text-left">
        <label for="selectCountryDropDown" class="auth-input">Country</label>
        <Dropdown class="p-inputtext-lg w-full" v-model="selectedCountry" :options="countryOptions" id="selectCountryDropDown" :filter="false" optionLabel="option" />
      </div>
    </div>
    <div class="mb-8">
      <div class="px-0 text-left">
        <label for="selectProvinceDropDown" class="auth-input">{{ selectedCountry.value === 'CA' ? 'Province' : 'State' }}</label>
        <Dropdown class="p-inputtext-lg w-full" v-model="user.practice_province" :options="provinceOptions" optionLabel="name" id="selectProvinceDropDown" :filter="false" @change="setProvince" :placeholder="selectedCountry.value === 'CA' ? 'Select Province' : 'Select State'" />
      </div>
    </div>
    <div class="flex flex-row items-center align-center" :class="user.practice_province ? ' justify-between' : 'justify-start'">
      <Button label="Previous" @click="goToPrevious()" iconPos="left" class="p-button-text p-button-sm p-button-secondary" icon="pi pi-caret-left" />
      <Button label="Next" @click="goToNext" id="submit" class="p-button-text p-button-sm" iconPos="right" icon="pi pi-arrow-right" v-if="user.practice_province" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['loggedInUser'],
  emits: ['goToNext', 'goToPrevious'],

  data() {
    return {
      animation: 'animate-in',
      user: this.loggedInUser,
      provinceOptions: [],
      countryOptions: [
        { option: 'Canada', value: 'CA' },
        { option: 'United States', value: 'US' },
      ],
      selectedCountry: { option: 'Canada', value: 'CA' },
    };
  },
  computed: {
    ...mapGetters(['actingUser', 'isGP', 'isSpecialist', 'isCme', 'allProvinces']),
    provinceOptions() {
      return this.allProvinces.filter((province) => province.country === this.selectedCountry.value);
    },
  },
  methods: {
    ...mapActions(['getAllProvinces', 'updateUserAccountInfo']),
    setProvince() {
      if (this.user.practice_province.id) {
        this.user.practice_province_id = this.user.practice_province.id;
        this.$emit('goToNext', this.user);
      }
    },
    goToNext() {
      if (this.user.practice_province) {
        this.$emit('goToNext', this.user);
      }
    },
    goToPrevious() {
      this.$emit('goToPrevious', this.user);
    },
  },
  created() {
    if (this.user.practice_province) {
      this.selectedCountry = this.countryOptions.find((country) => country.value === this.user.practice_province.country);
    }
    this.getAllProvinces()
      .then(() => {})
      .catch(() => {});
  },
};
</script>

<style></style>
