import axios from 'axios';
import globals from '../../globals';
import CapacitorCallPlugin from '@/plugins/CapacitorCallPlugin';
import { globalMixin } from '@/mixins';
import { Preferences } from '@capacitor/preferences';
import * as Sentry from '@sentry/vue';

const state = {
  accessToken: localStorage.getItem('accessToken'),
  loggedInUser: localStorage.getItem('loggedInUser'),
  actingUser: localStorage.getItem('actingUser'),
  oauthRegistered: localStorage.getItem('oauthRegistered'),
  lastLoggedInUserEmail: '',
  tokenLifeTimeInSeconds: 900,
  requiresToUploadBillingArrangement: localStorage.getItem('requiresToUploadBillingArrangement'),
  headerWithAuth: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    // Uncomment the following line when you want to connect to backend via Ngrok tunnel, must be
    // commented out in production:
    // 'ngrok-skip-browser-warning': true,
  },
  // timeFromStorageSinceLastTokenRefresh: localStorage.getItem('timeFromStorageSinceLastTokenRefresh'),
  // icdAccessToken: localStorage.getItem('icdAccessToken'),
};

const getters = {
  authState: () => {
    return state;
  },
  setHeaderWithAuth: () => {
    return state.headerWithAuth;
  },
  accessToken: () => {
    return state.accessToken;
  },
  // setIcdHeaderWithAuth: () => {
  //   return {
  //     'Content-Type': 'application/json',
  //     Accept: 'application/json',
  //     Authorization: 'Bearer ' + localStorage.getItem('icdAccessToken'),
  //     'API-Version': 'v2',
  //     'Accept-Language': 'en',
  //   };
  // },
  isLoggedIn: (state) => {
    if (state.accessToken !== null) return true;
    else return false;
  },
  loggedInUser: (state) => {
    if (state.loggedInUser !== null) {
      return JSON.parse(state.loggedInUser);
    } else return null;
  },
  lastLoggedInUserEmail: (state) => {
    if (state.lastLoggedInUserEmail !== null) {
      return state.lastLoggedInUserEmail;
    } else return null;
  },
  actingUser: (state) => {
    if (state.actingUser) {
      try {
        return JSON.parse(state.actingUser);
      } catch (e) {
        return state.actingUser;
      }
    } else return null;
  },
  isEmailActivated: (state) => {
    if (state.loggedInUser !== null) {
      let user = JSON.parse(state.loggedInUser);
      if (user.email_verified_at != null) return true;
      else return false;
    } else return false;
  },
  tokenLifeTimeInSeconds: (state) => {
    return state.tokenLifeTimeInSeconds;
  },
  timeFromStorageSinceLastTokenRefresh: () => {
    return state.timeFromStorageSinceLastTokenRefresh;
  }, // This getter is set to store when last time token was refreshed to be accessible from different tabs
  requiresToUploadBillingArrangement: (state) => {
    return JSON.parse(state.requiresToUploadBillingArrangement);
  },
};

// actions
const actions = {
  login(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.loginUrl(), credentials)
        .then((response) => {
          if (response.status == 200) {
            context
              .dispatch('storeLoggedInUser', response)
              .then((res) => {
                resolve(res);
              })
              .catch((error) => {
                reject(error.response);
              });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  registerOauth(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.registerOauthUrl(), credentials)
        .then((response) => {
          if (response.status == 200) {
            context
              .dispatch('storeLoggedInUser', response)
              .then((res) => {
                resolve(res);
              })
              .catch((error) => {
                reject(error.response);
              });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  demoLogin(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.demoLoginUrl(), data)
        .then((response) => {
          context
            .dispatch('storeLoggedInUser', response)
            .then((res) => {
              context.commit('setOnboardingForm', response.data.user.onboarding_form);
              context.commit('setActingUserOnboardingForm', response.data.user.onboarding_form);
              resolve(res);
            })
            .catch((error) => {
              reject(error.response);
            });
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  verifyOtpForUserLogin(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.verifyOtpForUserLoginUrl(), {
          email: data.email,
          verification_code: data.verificationCode,
          device_id: data.device_id,
        })
        .then((response) => {
          if (response.status == 200) {
            context
              .dispatch('storeLoggedInUser', response)
              .then((res) => {
                resolve(res);
              })
              .catch((error) => {
                reject(error.response);
              });
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  sendOtpToUser(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.sendOtpToUser(), {
          email: data.email,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  update2faSettings(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.update2faSettingsUrl(),
          {
            user_id: data.userId,
            verification_code: data.verificationCode,
            two_factor_auth_enabled: data.twoFactorAuthEnabled,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          context.commit('updateLoggedInUser', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  logout(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.logoutUrl(), {
          headers: context.getters.setHeaderWithAuth,
        })
        .then(async (response) => {
          context.commit('purgeLoggedInUser');
          context.commit('setTutorialStatus', '');
          context.dispatch('removeExternalReferralRequestCode');
          if (globalMixin.methods.isNativePlatform()) {
            CapacitorCallPlugin.logout();
            await Preferences.remove({ key: 'accessToken' });
          }
          resolve(response);
        })
        .catch(async (error) => {
          // Purging the user data regardless the server failed to log out the user,
          // which could be due to trying to logout using expired tokens.
          context.commit('purgeLoggedInUser');
          context.commit('setTutorialStatus', '');
          context.dispatch('removeExternalReferralRequestCode');
          if (globalMixin.methods.isNativePlatform()) {
            CapacitorCallPlugin.logout();
            await Preferences.remove({ key: 'accessToken' });
          }
          reject(error);
        });
    });
  },
  register(context, user) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.registerUrl(), user)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  sendPasswordResetEmail(context, email) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.sendPasswordResetEmailUrl(), {
          email: email,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  verifyPasswordReset(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.verifyPasswordResetUrl(), {
          user_id: data.userId,
          reset_code: data.resetCode,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  resetPassword(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.resetPasswordUrl(), {
          user_id: data.userId,
          reset_code: data.resetCode,
          password: data.password,
          password_confirmation: data.confirmPassword,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  verifyEmail(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.verifyEmailUrl(), {
          user_id: data.userId,
          verification_code: data.verificationCode,
        })
        .then((response) => {
          if (response.status == 200 && response.data.message === 'Your email has been verified') {
            context
              .dispatch('storeLoggedInUser', response)
              .then((res) => {
                resolve(res);
              })
              .catch((error) => {
                reject(error.response);
              });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  resendEmailVerificationEmail(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.resendEmailVerificationEmailUrl(), {
          user_id: data.userId,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  storeLoggedInUser(context, response) {
    return new Promise(async (resolve, reject) => {
      localStorage.setItem('loggedInUser', JSON.stringify(response.data.user));
      context.commit('setAccessToken', response.data);
      context.commit('saveLoggedInUser', response.data);
      context.commit('setOnboardingForm', response.data.user.onboarding_form);
      let promises = [];

      if (globalMixin.methods.isNativePlatform()) {
        try {
          await Preferences.set({
            key: 'accessToken',
            value: response.data.access_token,
          });
        } catch (e) {}
        CapacitorCallPlugin.setIsLoggedIn({ isLoggedIn: true });
        CapacitorCallPlugin.setLoggedInUser({ loggedInUser: { ...response.data.user, display_caller_welcome_modal: response.data.user.actions.find((action) => action.name === 'welcome_to_caller')?.status === 'Active' }, authAccessToken: response.data.access_token });
        promises.push(context.dispatch('getCallDisplayNames'));
      }
      Promise.all(promises)
        .then(() => {
          let user = response.data.user;
          // Sentry.setUser({
          //   id: user.id,
          //   email: user.email,
          //   role: user.role,
          //   status: user.status,
          // });
          let actingUser = null;
          if (user.status === 'Preboarding' || user.role === 'specialist' || user.role === 'gp') {
            actingUser = user;
            context.commit('setActingUser', actingUser);
            context
              .dispatch('checkIfUserIsAllowedToMakeReferral', actingUser.id)
              .then(() => {
                if (actingUser.provider_type === 'FFS' && actingUser?.practice_province?.billing_opt_in) {
                  let requiresToUploadBillingArrangement = !actingUser.billing_arrangements.find((b) => b.status == 'Submitted');
                  context.commit('setRequiresToUploadBillingArrangement', requiresToUploadBillingArrangement);
                }
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          } else if (user.role === 'org_unit_head') {
            axios
              .get(globals.APIs.getUsersByOrgUnitIdUrl(user.org_units[0].id), {
                headers: context.rootGetters.setHeaderWithAuth,
              })
              .then((res) => {
                actingUser = user;
                context.commit('setOrgUnitUsers', res.data.data);
                context.commit('setActingUser', actingUser);
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          } else if (user.role === 'hallway_admin') {
            actingUser = user;
            context.commit('setActingUser', actingUser);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshToken(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.refreshTokenUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then(async (response) => {
          context.commit('setAccessToken', response.data);
          if (globalMixin.methods.isNativePlatform()) {
            await Preferences.set({
              key: 'accessToken',
              value: response.data.access_token,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getLoggedInUser(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getLoggedInUserUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('updateLoggedInUser', response.data.data);
          if (globalMixin.methods.isNativePlatform()) {
            CapacitorCallPlugin.setLoggedInUser({ loggedInUser: { ...response.data.data, display_caller_welcome_modal: response.data.data.actions.find((action) => action.name === 'welcome_to_caller')?.status === 'Active' }, authAccessToken: context.rootGetters.accessToken });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  verifyUserInviteCode(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.verifyUserInviteCodeUrl(data), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};

// mutations
const mutations = {
  saveLoggedInUser: (state, data) => {
    state.accessToken = data.access_token;
    state.loggedInUser = JSON.stringify(data.user);
  },
  setActingUser: (state, data) => {
    state.actingUser = JSON.stringify(data);
    localStorage.setItem('actingUser', JSON.stringify(data));
  },
  updateActingUser: (state, data) => {
    state.actingUser = JSON.stringify(data);
    localStorage.setItem('actingUser', JSON.stringify(data));
  },
  updateLoggedInUser: (state, user) => {
    localStorage.setItem('loggedInUser', JSON.stringify(user));
    state.loggedInUser = JSON.stringify(user);
    if (globalMixin.methods.isNativePlatform()) {
      CapacitorCallPlugin.setLoggedInUser({ loggedInUser: { ...user, display_caller_welcome_modal: user.actions.find((action) => action.name === 'welcome_to_caller')?.status === 'Active' }, authAccessToken: state.accessToken });
    }
  },
  removeLoggedInUser: (state) => {
    state.loggedInUser = null;
    state.accessToken = null;
  },
  setAccessToken: (state, data) => {
    state.accessToken = data.access_token;
    state.headerWithAuth.Authorization = 'Bearer ' + state.accessToken;
    localStorage.setItem('accessToken', data.access_token);
    state.timeFromStorageSinceLastTokenRefresh = new Date().toString();
    localStorage.setItem('timeFromStorageSinceLastTokenRefresh', state.timeFromStorageSinceLastTokenRefresh);
  },
  setRequiresToUploadBillingArrangement: (state, data) => {
    state.requiresToUploadBillingArrangement = data;
    localStorage.setItem('requiresToUploadBillingArrangement', JSON.stringify(data));
  },
  setOauthRegistered: (state, data) => {
    localStorage.setItem('oauthRegistered', JSON.stringify(data));
    state.oauthRegistered = JSON.stringify(data);
  },
  purgeLoggedInUser: (state) => {
    // This is not needed anymore, as we removed the star feature
    // let lastLoggedInUser = JSON.parse(state.loggedInUser);
    // state.lastLoggedInUserEmail = lastLoggedInUser.email;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('actingUser');
    localStorage.removeItem('onboardingForm');
    localStorage.removeItem('actingUserOnboardingForm');
    localStorage.removeItem('isAllowedMakeReferral');
    localStorage.removeItem('requiresToUploadBillingArrangement');
    localStorage.removeItem('acsToken');
    localStorage.removeItem('callDisplayNames');
    localStorage.removeItem('timeFromStorageSinceLastTokenRefresh');
    localStorage.removeItem('isLecturePlaying');
    Sentry.setUser(null);
    state.loggedInUser = null;
    state.actingUser = null;
    state.accessToken = null;
    state.headerWithAuth.Authorization = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
